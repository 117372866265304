import { CANVAS_CM_TO_PIXEL_RATIO, CANVAS_HEIGHT_CM, CANVAS_WIDTH_CM } from '../AppConfig';

const MIN_PADDING = 10;

export function GetRandomPoints(collectible) {
  const collectibleWidth = collectible.width * CANVAS_CM_TO_PIXEL_RATIO;
  const collectibleHeight = collectible.height * CANVAS_CM_TO_PIXEL_RATIO;
  const xMin = Math.floor(collectibleWidth / 2 + MIN_PADDING);
  const xMax = Math.floor(
    CANVAS_WIDTH_CM * CANVAS_CM_TO_PIXEL_RATIO - collectibleWidth / 2 - MIN_PADDING
  );
  const yMin = Math.floor(collectibleHeight / 2 + MIN_PADDING);
  const yMax = Math.floor(
    CANVAS_HEIGHT_CM * CANVAS_CM_TO_PIXEL_RATIO - collectibleHeight / 2 - MIN_PADDING
  );
  const x = Math.floor(Math.random() * (xMax - xMin + 1) + xMin);
  const y = Math.floor(Math.random() * (yMax - yMin + 1) + yMin);
  return [x, y];
}
