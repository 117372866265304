import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../components/Button/DefaultButton';
import { Canvas } from '../components/Canvas/Canvas';
import SaveExhibitionForm from '../components/Exhibition/SaveExhibition';
import { Page } from '../components/Page/Page';
import {
  editExhibition,
  getTempExhibitionId,
  saveExhibitionPreviewImage
} from '../services/ExhibitionService';
import { useAppState } from '../store/appState';
import { createExhibition } from '../utils/ExhibitionGenerator';
import { GenerateScreenshot } from '../utils/ScreenshotGenerator';
import log from '../utils/Logger';
import FooterButtons from '../components/FooterButtons';
import ModalContent, { ModalErrorAlertInfoMessages } from '../components/Modal/ModalContent';
import Modal from '../components/Modal/Modal';
import ExhibitionSelection from './ExhibitionSelection';
import ImageSelectionClassic from './ImageSelectionClassic';
import { AnimatePresence } from 'framer-motion';
import { IoTrashOutline } from 'react-icons/io5';
import { WALL_NAME } from '../AppConfig';

export default function Main() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(undefined);
  const [animationType, setAnimationType] = useState(undefined);
  const stageRef = useRef();
  const placedImages = useAppState((state) => state.placedImages);
  const setPlacedImages = useAppState((state) => state.setPlacedImages);
  const resetState = useAppState((state) => state.restart);
  const showSaveButton = useAppState((state) => state.showSaveButton);
  const setShowSaveButton = useAppState((state) => state.setShowSaveButton);
  const showMapButton = useAppState((state) => state.showMapButton);
  const setShowMapButton = useAppState((state) => state.setShowMapButton);
  const [imageSelectionViewMode, setImageSelectionViewMode] = useState('list');
  const [showBin, setShowBin] = useState(false);

  const restart = () => {
    if (showSaveButton) {
      // show warning if pics placed and not saved
      setModalOpen('warningExhibitionNotSaved');
      return;
    }
    resetState();
    setAnimationType('horizontal');
    navigate('/');
  };

  const moreImages = () => {
    setImageSelectionViewMode('list');
    setModalOpen('moreImages');
    setShowMapButton(true);
  };

  const openMap = () => {
    setImageSelectionViewMode('map');
    setModalOpen('moreImages');
  };

  const showExhibitions = () => {
    setModalOpen('showExhibitions');
  };

  const onClickSaveButton = () => {
    if (placedImages?.length > 0) {
      setModalOpen('saveExhibition');
    } else {
      setModalOpen('noImagesPlaced');
    }
  };

  const saveComposition = async (title, author) => {
    const screenshot = GenerateScreenshot(stageRef.current);
    const previewMediaId = await saveExhibitionPreviewImage(screenshot);
    const exhibition = createExhibition(placedImages, previewMediaId, title, author);
    const tempExhibitionId = getTempExhibitionId();
    if (tempExhibitionId > 0) {
      const res = await editExhibition(tempExhibitionId, exhibition, true);
    } else {
      log.debug('something went wrong with saving new exhibition');
    }
    setShowSaveButton(false);
    showExhibitions();
  };

  return (
    <Page
      bg={WALL_NAME == 'WandLinks' ? 'bg-wallLeft' : 'bg-wallRight'}
      animationType={animationType}>
      <div id="main" className="grid grid-cols-4 gap-4 pt-6 place-content-start place-items-center">
        <Canvas
          className="relative col-span-4 "
          placedImages={placedImages}
          setPlacedImages={setPlacedImages}
          stageRef={stageRef}
          readonly={false}
          setShowBin={setShowBin}>
          {showBin && (
            <IoTrashOutline
              id="deleteBin"
              className="absolute -right-14 top-0 text-5xl text-white bg-red-800"
            />
          )}
        </Canvas>

        <FooterButtons classNameAddon={' gap-6 '}>
          <div className="col-span-1">
            <DefaultButton text="SAMMLUNG" onButtonClicked={moreImages} size="bold" />
          </div>
          <div className="col-span-1">
            <DefaultButton
              text="KARTE"
              onButtonClicked={openMap}
              size="bold"
              hidden={!showMapButton}
            />
          </div>
          <div className="col-span-1">
            <DefaultButton
              text="SPEICHERN"
              onButtonClicked={onClickSaveButton}
              size="bold"
              hidden={!showSaveButton}
            />
          </div>
          <div className="col-span-2 grid grid-rows-2 gap-3 place-items-center ">
            <DefaultButton text="NEU STARTEN" className="" onButtonClicked={restart} size="thin" />
            <DefaultButton text="HALL OF FAME" onButtonClicked={showExhibitions} size="thin" />
          </div>
        </FooterButtons>

        {modalOpen == 'saveExhibition' && (
          <SaveExhibitionForm
            setModalOpen={setModalOpen}
            saveComposition={saveComposition}></SaveExhibitionForm>
        )}
        <AnimatePresence>
          {modalOpen == 'showExhibitions' && (
            <ExhibitionSelection setModalOpen={setModalOpen}></ExhibitionSelection>
          )}
          {modalOpen == 'moreImages' && (
            <ImageSelectionClassic
              setModalOpen={setModalOpen}
              setImageSelectionViewMode={setImageSelectionViewMode}
              imageSelectionViewMode={imageSelectionViewMode}></ImageSelectionClassic>
          )}
        </AnimatePresence>
        {modalOpen == 'noImagesPlaced' && (
          <Modal
            onClose={() => {
              setModalOpen(undefined);
            }}>
            <ModalContent
              title=""
              buttonText="OK"
              onClick={() => {
                setModalOpen(undefined);
              }}>
              <div>Was willst du speichern? Noch gibt es nichts zu sehen auf der Wand</div>
            </ModalContent>
          </Modal>
        )}
        {modalOpen == 'warningExhibitionNotSaved' && (
          <Modal
            onClose={() => {
              setModalOpen(undefined);
            }}>
            <ModalErrorAlertInfoMessages
              message="Deine Änderungen gehen beim Neu Starten verloren."
              confirm={() => {
                resetState();
                setAnimationType('horizontal');
                navigate('/');
              }}
              confirmText="OK"></ModalErrorAlertInfoMessages>
          </Modal>
        )}
      </div>
    </Page>
  );
}
