import { useState } from 'react';
import { IoCheckmarkCircle, IoCloseCircleSharp } from 'react-icons/io5';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';
import { REACT_APP_APISERVER } from '../../AppConfig';
import TagsListImage from '../Tags/TagsListImage';
import { delay } from '../../utils/Delay';
import { useSwipeable } from 'react-swipeable';

export default function ImageStackCard({ collectible, onClickImage, index }) {
  const [hidden, setHidden] = useState(false);
  const [exit, setExit] = useState(undefined);

  const x = useMotionValue(0);

  const onClick = async (confirmed) => {
    // setShowOnboardingAnimation(false);
    if (confirmed) {
      setExit({ x: 300, opacity: 0 });
    } else {
      setExit({ x: -300, opacity: 0 });
    }
    await delay(100);

    onClickImage?.(collectible, confirmed);
    setHidden(true);
  };
  const classAddon = ' top-' + (index - 2);
  const animate = { x: 0, opacity: 1 };
  const initial = { x: 0, opacity: 0 };
  const handlers = useSwipeable({
    onSwipedLeft: () => onClick(false),
    onSwipedRight: () => onClick(true),
    swipeDuration: 2000,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <AnimatePresence initial={false}>
      {!hidden ? (
        <motion.div
          className={
            `bg-gray-200 border-2 border-gray-400 p-6 absolute shadow-lg h-3/4 w-1/2 ` + classAddon
          }
          key={`${collectible.id}-card`}
          style={{ x }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          exit={exit}
          initial={initial}
          animate={animate}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
            repeat: false,
            duration: 0.5
          }}
          {...handlers}>
          <div className={'flex flex-col justify-center place-items-center'}>
            {/* image */}
            <div className="h-96 font-semibold flex flex-col justify-start place-items-center">
              <img
                className="max-h-full pb-4"
                src={REACT_APP_APISERVER + collectible?.previewMedia?.url}
                key={collectible?.id}
              />
              {collectible?.title?.de}
            </div>
            {/* <div className="pt-4 font-semibold ">{collectible?.title?.de}</div> */}
            {/* tags list of image */}
            <div className="pt-12 w-full">
              <TagsListImage tags={collectible.tags}></TagsListImage>
            </div>
            {/* checkboxes */}
            <div className="absolute bottom-3">
              <div
                className={`inline-block px-5`}
                onClick={() => {
                  onClick(false);
                }}>
                <IoCloseCircleSharp className="text-5xl " />
              </div>
              <div
                className={`inline-block px-5 `}
                onClick={() => {
                  onClick(true);
                }}>
                <IoCheckmarkCircle className="text-5xl" />
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        ''
      )}
    </AnimatePresence>
  );
}
