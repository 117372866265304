import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { AnimatePresence, motion } from 'framer-motion';
import { bottomUpAnimationProps } from '../../utils/Animation';

export default function Modal({
  onClose,
  onClickBeside = onClose,
  children,
  className,
  size = ' w-1/2 ',
  showBackHint
}) {
  const defaultClasses = 'bg-white m-auto relative h-screen ' + size;

  const subParentClasses =
    size == ' w-11/12 ' ? 'fixed left-0 w-full h-full z-50 overflow-scroll' : '';
  return (
    // <AnimatePresence>
    <div
      onClick={() => {
        onClickBeside();
      }}
      className="fixed top-0 left-0 w-full h-full z-50 overflow-scroll"
      style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
      <div className={subParentClasses}>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={defaultClasses + className}>
          {showBackHint ? (
            <div
              id="close-modal"
              onClick={() => {
                onClose();
              }}
              className="absolute flex flex-col transition transform hover:scale-105 text-freisicht hover:text-darkGreen text-4xl top-2 right-4 cursor-pointer z-30">
              <IoCloseOutline />
              <div className="text-xs">Zurück</div>
            </div>
          ) : (
            <div
              id="close-modal"
              onClick={() => {
                onClose();
              }}
              className="absolute transition transform hover:scale-105 text-freisicht hover:text-darkGreen text-5xl top-2 right-4 cursor-pointer z-30">
              <IoCloseOutline />
            </div>
          )}

          {size == ' w-11/12 ' ? (
            <motion.div
              className="relative h-full w-full"
              key={'modalContent'}
              {...bottomUpAnimationProps}>
              {children}
            </motion.div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
    // </AnimatePresence>
  );
}
