import { useEffect, useRef, useState } from 'react';
import { Canvas } from '../components/Canvas/Canvas';
import { Page } from '../components/Page/Page';
import { getActiveExhibition } from '../services/ExhibitionService';
import log from '../utils/Logger';
import { CANVAS_CM_TO_PIXEL_RATIO, REACT_APP_APISERVER } from '../AppConfig';
import { stopDefaultExhibitionLoop } from '../utils/DefaultExhibitionLoop';

export default function ProjectorView() {
  const [placedImages, setPlacedImages] = useState([]);
  const [runningInterval, setRunningInteval] = useState(undefined);
  const stageRef = useRef();

  stopDefaultExhibitionLoop();

  async function getAndDisplayActiveExhibition() {
    log.debug('loop is active!');
    const exhibitionToShow = await getActiveExhibition();
    log.debug('load comp: ', exhibitionToShow);
    const loadedImages = [];
    exhibitionToShow?.activeExhibition?.exhibitionPictures?.map((pic) => {
      const x = pic.position[0] * CANVAS_CM_TO_PIXEL_RATIO;
      const y = pic.position[1] * CANVAS_CM_TO_PIXEL_RATIO;
      const scale = pic.rotation[0] || 1;
      loadedImages.push({
        x,
        y,
        scale,
        src: REACT_APP_APISERVER + pic.collectible?.previewMedia?.url,
        collectibleId: pic.collectible.id,
        collectibleWidth: pic.collectible.width,
        collectibleHeight: pic.collectible.height
      });
    });
    setPlacedImages(loadedImages);
  }

  const loadComposition = async () => {
    if (runningInterval) return;
    const interval = setInterval(getAndDisplayActiveExhibition, 1000 * 2);
    setRunningInteval(interval);
  };

  useEffect(() => {
    log.debug('useEffect called');
    loadComposition();
  }, []);

  return (
    <Page bg="bg-wall">
      <div id="main" className="grid grid-cols-4 gap-4 place-items-center">
        <Canvas
          className="col-span-4 pt-16"
          placedImages={placedImages}
          stageRef={stageRef}
          readonly={true}
        />
      </div>
    </Page>
  );
}
