import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../components/Button/DefaultButton';
import FooterButtons from '../components/FooterButtons';
import ImageSelection from '../components/ImageSelection/ImageSelection';
import MapView from '../components/Map/MapView';
import Modal from '../components/Modal/Modal';
import { Page } from '../components/Page/Page';
import { getCollection } from '../services/CollectionService';
import { useAppState } from '../store/appState';
import { bottomUpAnimationProps, listcardAnimationProps } from '../utils/Animation';
import { doFilterByTextAndTags } from '../utils/Filter';
import log from '../utils/Logger';
import { toggleImagePlaced } from '../utils/ToggleImagePlaced';
import ContentSelection from './ContentSelection';
import { ModalImageDetailContent } from '../components/Modal/ModalContent';

export default function ImageSelectionClassic({
  setModalOpen,
  setImageSelectionViewMode,
  imageSelectionViewMode
}) {
  const query = useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [filteredImages, setFilteredImages] = useState(query.data?.collection?.collectible);
  const placedImages = useAppState((state) => state.placedImages);
  const setPlacedImages = useAppState((state) => state.setPlacedImages);
  const selectedTags = useAppState((state) => state.selectedTags);
  const rejectedImages = useAppState((state) => state.rejectedImages);
  const [additionalModalOpen, setAdditionalModalOpen] = useState(undefined);

  useEffect(() => {
    if (query.data && selectedTags.length === 0 && searchText.length === 0) {
      setFilteredImages(query.data?.collection?.collectible);
    }
  }, [query]);

  useEffect(() => {
    filterByTags(selectedTags);
  }, [selectedTags]);

  const onClick = (collectible) => {
    toggleImagePlaced(collectible, placedImages, setPlacedImages);
  };

  const onImageClick = (collectible) => {
    setAdditionalModalOpen(collectible);
  };

  const filterByTags = (tags) => {
    filterImages(searchText, tags);
  };

  const filterBySearch = (text) => {
    setSearchText(text);
    filterImages(text, selectedTags);
  };

  const filterImages = (text, tags) => {
    const newFilteredImages = doFilterByTextAndTags(
      text,
      tags,
      query.data?.collection?.collectible,
      true,
      placedImages
    );

    if (newFilteredImages?.length == query.data?.collection?.collectible?.length) {
      log.debug('case 1');
      setFilteredImages(query.data?.collection?.collectible);
    } else if (newFilteredImages?.length > filteredImages?.length) {
      log.debug('case 2');
      const combinedList = newFilteredImages
        .filter((x) => !filteredImages.some((i) => i.id == x.id))
        .map((x) => ({ ...x, highlight: true }))
        .concat(filteredImages.map((x) => ({ ...x, highlight: false })));
      setFilteredImages(combinedList);
      log.debug('combinedList: ', combinedList);
    } else {
      log.debug('case 3');
      setFilteredImages(newFilteredImages);
    }
  };

  const changeMode = (mode) => {
    log.debug('ok changeMode to: ', mode);
    setImageSelectionViewMode(mode);
  };

  return (
    <motion.div key={'imageSelectionModal'} {...listcardAnimationProps}>
      <Modal
        onClose={() => {
          setModalOpen(undefined);
        }}
        size=" w-11/12 "
        showBackHint={true}>
        <ContentSelection
          title="SAMMLUNG"
          filterBySearch={filterBySearch}
          searchText={searchText}
          showTags={true}
          id="imageselectionclassic">
          {imageSelectionViewMode == 'list' && (
            <ImageSelection
              filteredImages={filteredImages}
              onClick={onClick}
              onImageClick={onImageClick}
              placedImages={placedImages}
              rejectedImages={rejectedImages}
            />
          )}
          {imageSelectionViewMode == 'map' && (
            <MapView
              images={filteredImages}
              onClick={onClick}
              onImageClick={onImageClick}
              placedImages={placedImages}
            />
          )}
        </ContentSelection>
        <FooterButtons classNameAddon="justify-center">
          <DefaultButton
            text={'GALERIE'}
            onButtonClicked={() => changeMode('list')}
            size="wide"
            filled={!(imageSelectionViewMode == 'map')}
          />
          <DefaultButton
            text={'KARTE'}
            onButtonClicked={() => changeMode('map')}
            size="wide"
            filled={!(imageSelectionViewMode == 'list')}
          />
        </FooterButtons>
        {additionalModalOpen && (
          <Modal
            onClose={() => {
              setAdditionalModalOpen(undefined);
            }}
            size=" w-2/3 ">
            <ModalImageDetailContent imagedata={additionalModalOpen} />
          </Modal>
        )}
      </Modal>
    </motion.div>
  );
}
