import { deleteToApi, getFromApi, postToApi, putToApi } from './APIService';
import log from '../utils/Logger';
import { COLLECTION_ID, WALL_NAME } from '../AppConfig';
import { useAppState } from '../store/appState';

// used to display exhibitions before they are actually saved by user
let tempExhibitionId = -1;

export function getTempExhibitionId() {
  return tempExhibitionId;
}

export async function getExhibitions() {
  let res = await getFromApi(`collections/${COLLECTION_ID}/exhibitions`, {});
  let exhibitions = res.exhibitions;
  return exhibitions;
}

export async function getExhibition(id) {
  log.debug('getExhibition with id ', id);
  let res = await getFromApi(`collections/${COLLECTION_ID}/exhibitions/${id}`, {});
  return res;
}

export async function saveExhibition(exhibition) {
  log.debug('saveExhibition ', exhibition);
  exhibition.isHidden = true;
  let res = await postToApi(`collections/${COLLECTION_ID}/exhibitions`, exhibition);
  log.debug('saveExhibition result ', res);
  if (tempExhibitionId > 0) {
    await deleteExhibition(tempExhibitionId);
  }
  tempExhibitionId = res.newExhibitionId;
  setActiveExhibition(tempExhibitionId);
  return true;
}

export async function editExhibition(id, exhibition, publish) {
  log.debug('editExhibition with id ', id);
  exhibition.isHidden = publish ? false : true;
  let res = await putToApi(`collections/${COLLECTION_ID}/exhibitions/${id}`, exhibition);
  if (publish) tempExhibitionId = -1;
  return res;
}

export async function deleteExhibition(id) {
  log.debug('deleteExhibition with id ', id);
  let res = await deleteToApi(`collections/${COLLECTION_ID}/exhibitions/${id}`);
  return res;
}

export async function setActiveExhibition(id) {
  log.debug('set active exhibition ', id);
  let res = await putToApi(
    `collections/${COLLECTION_ID}/exhibitions/activeWall/${WALL_NAME}/${id}`
  );
  log.debug('set active exhibition result ', res);
  if (tempExhibitionId > 0 && tempExhibitionId !== id) {
    await deleteExhibition(tempExhibitionId);
    tempExhibitionId = -1;
  }
  return true;
}

export async function getActiveExhibition() {
  log.debug('get active exhibition ');
  let res = await getFromApi(`collections/${COLLECTION_ID}/exhibitions/activeWall/${WALL_NAME}`);
  log.debug('get active exhibition result ', res);
  return res;
}

export async function saveExhibitionPreviewImage(image) {
  log.debug('saveExhibition image ', image);
  const formData = new FormData();
  formData.append('previewImage', image);
  let res = await postToApi(`collections/${COLLECTION_ID}/exhibitions/previewImage`, formData);
  log.debug('saveExhibition result ', res);
  // TODO: read out mediaId and return before saving exhibition
  return res?.id;
}

export function filterHiddenExhibitions(exhibitions, adminMode) {
  // only filter out tmp exhibitions and not all hidden exhibitions in admin mode

  log.debug('filterExhibitions, adminMode: ', adminMode);
  if (adminMode !== 2) {
    return exhibitions.filter((ex) => !ex.isHidden);
  } else {
    return exhibitions.filter((ex) => !(ex.isHidden && ex.title == 'tmp'));
  }
}
