import { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { getTagColorClass } from '../../utils/TagColor';

export default function Tag({ tag, selected, toggleTag, showXOnSelected, specialColor }) {
  // delay needed because of animation bug in auto-animate
  const [selectedDelayed, setSelectedDelayed] = useState(selected);

  useEffect(() => {
    if (selected && showXOnSelected) {
      setTimeout(() => setSelectedDelayed(true), 100);
    } else {
      setSelectedDelayed(false);
    }
  }, [selected]);

  let classNameAddon = getTagColorClass(tag, selected);
  classNameAddon = !showXOnSelected && selected ? ' bg-gray-300 text-black ' : classNameAddon;
  return (
    <div
      className={
        'flex-none px-5 py-1 mx-2 hover:cursor-pointer rounded-full transition transform active:scale-105 ' +
        classNameAddon
      }
      onClick={() => toggleTag?.(tag, !selected)}>
      {tag.name.de}
      {selectedDelayed && <IoCloseSharp className="ml-4 inline text-white" />}
    </div>
  );
}
