import { IoCloseOutline } from 'react-icons/io5';
import Header from '../components/Header';

export default function ContentSelection({
  onBackButtonClick,
  filterBySearch,
  searchText,
  showTags,
  id,
  children,
  title
}) {
  return (
    <>
      <div className="absolute top-4 inset-x-0">
        <h1>{title}</h1>
      </div>
      {onBackButtonClick && (
        <div
          className="absolute top-4 right-4 hover:cursor-pointer z-20"
          onClick={onBackButtonClick}>
          <IoCloseOutline className="text-4xl" />
        </div>
      )}

      <div id={id} className="grid grid-cols-4 gap-4 place-items-start h-32">
        <Header
          filterBySearch={filterBySearch}
          searchText={searchText}
          classNameAddon=" col-span-4 "
          showTags={showTags}
        />
      </div>
      {children}
    </>
  );
}
