import React from 'react';
import { useState } from 'react';
import ModalContent from '../Modal/ModalContent';
import Modal from '../Modal/Modal';
import InputGeneral from '../Input/InputGeneral';
import log from '../../utils/Logger';
import { login } from '../../services/LoginService';
import { useAppState } from '../../store/appState';

export default function LoginForm({ setModalOpen }) {
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const setAdminMode = useAppState((state) => state.setAdminMode);

  const onLoginButtonClicked = async () => {
    if (!username || !password) {
      setMessage('Bitte alle Felder ausfüllen.');
      return;
    }
    log.debug('login to admin called');

    const loginSuccess = await login(username, password);
    if (!loginSuccess) {
      setMessage('Login nicht erfolgreich.');
      return;
    }

    // TODO: maybe redirect to main page after admin login?
    setAdminMode(2);
    setModalOpen('');
  };

  return (
    <Modal
      onClose={() => {
        setAdminMode(0);
        setModalOpen(undefined);
      }}>
      <ModalContent title="" buttonText="LOGIN" onClick={onLoginButtonClicked}>
        <InputGeneral
          value={username}
          onChange={setUsername}
          placeholder="Username"
          classNameAddon=" my-8 w-full"
        />
        <InputGeneral
          value={password}
          onChange={setPassword}
          placeholder="Passwort"
          classNameAddon=" my-8 w-full"
        />
        {message && (
          <div className="w-full text-sm px-4">
            <p className="text-left text-red-800">{message}</p>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
}
