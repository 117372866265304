import { MapContainer, Marker, Popup, TileLayer, AttributionControl } from 'react-leaflet';
import L, { icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { v4 as uuidv4 } from 'uuid';
import log from '../../utils/Logger';
import ListCard from '../ListCard';
import { useAppState } from '../../store/appState';

export default function MapView({ images, onClick, onImageClick, placedImages }) {
  const open = useAppState((state) => state.open);
  const listposition = open ? ' pt-10 ' : '';
  const outerBounds = [
    [47.435, 8.0],
    [47.375, 8.1]
  ];

  const customIcon = L.icon({
    iconUrl: 'map-solid-240.png',
    shadowUrl: 'map-solid-240.png',

    iconSize: [40, 40], // size of the icon
    shadowSize: [0, 0], // size of the shadow
    iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [-2, -5] // point from which the popup should open relative to the iconAnchor
  });

  return (
    <div className="flex items-start h-screen">
      <div className={'h-3/4 w-4/5 pb-6 mx-auto z-0 ' + listposition}>
        <MapContainer
          // style={{ height: '500px', width: '1000px' }}
          className="h-full w-full"
          center={[47.39498060810732, 8.04729432713171]}
          minZoom={14}
          zoom={14}
          maxZoom={18}
          maxBounds={outerBounds}
          scrollWheelZoom={true}
          attributionControl={false}>
          <TileLayer
            url="map_aarau/{z}/{x}/{y}.png"
            attribution={'&copy; OpenStreetMap contributors'}
          />
          <AttributionControl position="bottomright" prefix="Leaflet" />
          <MarkerClusterGroup maxClusterRadius={40} removeOutsideVisibleBounds={false}>
            {images &&
              images.map((image, index) => {
                const content = (
                  <>
                    <div className="font-bold">{image?.title?.de}</div>
                    <div>{image?.artist?.de + ', ' + image?.date?.de}</div>
                  </>
                );
                return image?.geoLocation ? (
                  <Marker
                    position={image.geoLocation}
                    icon={customIcon}
                    key={'marker-' + image?.id}>
                    <Popup minWidth={200}>
                      <ListCard
                        data={image}
                        image={image?.thumbnailMedia?.url}
                        key={'card-' + image?.id}
                        onClick={onClick}
                        onImageClick={onImageClick}
                        content={content}
                        isPlaced={placedImages.some((x) => x.collectibleId == image.id)}
                        showCircles={true}
                        dropShadow={false}
                      />
                    </Popup>
                  </Marker>
                ) : (
                  ''
                );
              })}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </div>
  );
}
