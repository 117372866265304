import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DefaultButton from '../components/Button/DefaultButton';
import FooterButtons from '../components/FooterButtons';
import { Page } from '../components/Page/Page';
import TagsListStart from '../components/Tags/TagsListStart';
import { getCollection } from '../services/CollectionService';
import { useAppState } from '../store/appState';
import { stopDefaultExhibitionLoop } from '../utils/DefaultExhibitionLoop';
import { doFilterByTextAndTags } from '../utils/Filter';
import log from '../utils/Logger';

export default function TagSelection() {
  const navigate = useNavigate();
  const selectedTags = useAppState((state) => state.selectedTags);
  const collectionQuery = useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });

  useEffect(() => {
    stopDefaultExhibitionLoop();
  }, []);

  const getNumberOfImagesForTags = () => {
    return doFilterByTextAndTags('', selectedTags, collectionQuery.data?.collection?.collectible)
      ?.length;
  };

  const getNumberOfFilteredImagesText = () => {
    const number = getNumberOfImagesForTags();
    const tagsSelected = selectedTags?.length;
    const bildText = number > 1 ? 'Bilder' : 'Bild';
    if (!tagsSelected) return `Es gibt insgesamt ${number} Bilder.`;
    const info = selectedTags
      .map((tag, index, { length }) => {
        if (index + 1 === length) {
          return ' ' + tag.name.de + '.';
        } else if (index + 2 === length) {
          return ' ' + tag.name.de + ' und ';
        } else {
          return ' ' + tag.name.de + ', ';
        }
      })
      .join('');
    return `Es gibt ${number} ${bildText} zum Thema ${info}`;
  };

  const onConfirm = () => {
    navigate('/imageselection-stack');
  };
  return (
    <Page bg="bg-aarau" classAddon="grid">
      <div id="tagselection" className="relative ">
        <div className="text-4xl pt-20">Was interessiert dich?</div>
        <div>Wähle verschiedene Begriffe aus.</div>
        {/* <div>{getNumberOfFilteredImagesText()}</div> */}

        <TagsListStart />
      </div>
      <FooterButtons classNameAddon={'justify-center'}>
        <DefaultButton
          text={'WEITER'}
          onButtonClicked={onConfirm}
          hidden={selectedTags.length === 0}
        />
      </FooterButtons>
    </Page>
  );
}
