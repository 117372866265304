import React from 'react';
import DefaultButton from '../Button/DefaultButton';
import Modal from './Modal';
import { REACT_APP_APISERVER } from '../../AppConfig';

export default function ModalContent({ title, children, buttonText, onClick }) {
  return (
    <div className="flex flex-col justify-center place-items-center py-16">
      <div className="font-bold text-2xl py-8">{title}</div>
      {children}
      <div className="pt-10">
        <DefaultButton text={buttonText} onButtonClicked={onClick} />
      </div>
    </div>
  );
}

export function ModalImageDetailContent({ imagedata }) {
  return (
    <>
      <div className="z-20 pt-16 w-full h-3/4 flex justify-center">
        <img
          className="max-w-full "
          src={REACT_APP_APISERVER + imagedata?.previewMedia?.url}
          key={imagedata?.id}></img>
      </div>
      <div className="px-4 pt-2">
        <div className="font-bold">{imagedata?.title?.de}</div>
        <div>{imagedata?.artist?.de + ', ' + imagedata?.date?.de}</div>
        <div>{imagedata?.materialTechnique?.de}</div>
      </div>
    </>
  );
}

export function ModalErrorAlertInfoMessages({ message, confirm, confirmText, reject, rejectText }) {
  return (
    <div className="flex flex-col justify-center place-items-center py-32">
      <div className="py-8 px-6 ">
        {message}
        <div className="flex justify-center pt-16 space-x-6">
          {confirm && confirmText && (
            <DefaultButton text={confirmText} onButtonClicked={confirm}></DefaultButton>
          )}
          {/*   {reject && rejectText && (
            <DefaultButton text={rejectText} onButtonClicked={reject}></DefaultButton>
          )} */}
        </div>
      </div>
    </div>
  );
}

// this function is not used
export function ModalErrorAlertInfo({
  setMessage,
  setAcceptData,
  message,
  acceptData,
  acceptFunction
}) {
  return (
    <Modal
      onClose={() => {
        setMessage?.(undefined);
        setAcceptData?.(undefined);
      }}>
      <ModalErrorAlertInfoMessages
        message={message}
        confirm={acceptData ? acceptFunction : undefined}
        confirmText={acceptData ? 'ok' : ''}
        reject={() => {
          setMessage?.(undefined);
          setAcceptData?.(undefined);
        }}
        rejectText={acceptData ? 'abbrechen' : 'ok'}></ModalErrorAlertInfoMessages>
    </Modal>
  );
}
