import { useEffect, useState } from 'react';
import { IoCheckmarkCircle, MdZoomIn } from 'react-icons/io5';
import { RiZoomInFill } from 'react-icons/ri';
import { REACT_APP_APISERVER } from '../AppConfig';
import log from '../utils/Logger';

export default function ListCard({
  data,
  image,
  content,
  onClick,
  onImageClick,
  showCircles,
  isPlaced,
  dropShadow,
  zoom = true
}) {
  const [effect, setEffect] = useState(false);

  const markPlaced = isPlaced ? 'text-tagCurrentSelectionActive' : 'text-white';
  let shadow = dropShadow ? ' shadow-lg ' : ' ';
  shadow =
    dropShadow && isPlaced
      ? shadow + ' border-2 border-gray-300 '
      : ' border-2 border-gray-200 ' + shadow;
  return (
    <div
      className={`${effect && 'animate-scaleit'} h-72 text-sm flex justify-center ` + shadow}
      onClick={() => {
        setEffect(true);
        onClick(data);
      }}
      onAnimationEnd={() => {
        setEffect(false);
      }}>
      <div className="w-[12.18rem] relative bg-gray-200 p-1">
        <div
          className="h-32 flex justify-center"
          onClick={(e) => {
            e.stopPropagation();
            onImageClick ? onImageClick(data) : onClick(data);
          }}>
          <img
            className="max-h-32 "
            src={REACT_APP_APISERVER + image}
            key={data?.id}
            loading="lazy"
          />
          {zoom ? <RiZoomInFill className="absolute right-2 top-28 z-20 text-xl " /> : ''}
        </div>
        <div className="h-24 overflow-scroll pt-2">{content}</div>
        {showCircles && (
          <div>
            <div className={'absolute left-1/2 transform -translate-x-1/2 bottom-2  ' + markPlaced}>
              <IoCheckmarkCircle
                className="text-4xl rounded-full bg-black "
                viewBox="20 20 470 470"></IoCheckmarkCircle>
            </div>
            {/* 
            <div className={`absolute top-20 left-4 text-white rounded-full ${markRejected} `}>
              <BiXCircle className="text-4xl "></BiXCircle>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
