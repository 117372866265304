import React, { useEffect } from 'react';
import { useState } from 'react';
import ModalContent from '../Modal/ModalContent';
import Modal from '../Modal/Modal';
import InputGeneral from '../Input/InputGeneral';
import log from '../../utils/Logger';

export default function SaveExhibitionForm({ saveComposition, setModalOpen }) {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  const saveExhibition = async () => {
    if (!title || !author) {
      setMessage('Bitte alle Felder ausfüllen.');
      return;
    }
    log.debug('save exhibition called');
    await saveComposition(title, author);
  };

  useEffect(() => {
    if (title && author && message) {
      setMessage('');
    }
  }, [title, author]);

  return (
    <Modal
      onClose={() => setModalOpen(undefined)}
      onClickBeside={() => {
        if (!inputFocused) {
          setModalOpen(undefined);
        }
      }}>
      <ModalContent title="" buttonText="SPEICHERN" onClick={saveExhibition}>
        <InputGeneral
          value={title}
          onChange={setTitle}
          onBlur={() => setTimeout(() => setInputFocused(false), 300)}
          onFocus={() => {
            setInputFocused(true);
            // needed when switched directly from input to another
            setTimeout(() => setInputFocused(true), 400);
          }}
          onKeyUp={(event) => {
            event.preventDefault();
            event.target.blur();
          }}
          placeholder="Titel der Projektion"
          classNameAddon=" my-8 w-full"
        />
        <InputGeneral
          value={author}
          onChange={setAuthor}
          onBlur={() => setTimeout(() => setInputFocused(false), 300)}
          onFocus={() => {
            setInputFocused(true);
            // needed when switched directly from input to another
            setTimeout(() => setInputFocused(true), 400);
          }}
          onKeyUp={(event) => {
            event.preventDefault();
            event.target.blur();
          }}
          placeholder="Name der Kurator:in"
          classNameAddon=" my-8 w-full"
        />
        {message && (
          <div className="w-full text-sm px-4 h-12">
            <p className="text-left text-red-800">{message}</p>
          </div>
        )}
        <div className="w-full p-4">
          <p className="text-left">
            Nach dem Speichern erscheint deine Komposition mit Name und Titel in der Hall of Fame.
          </p>
        </div>
      </ModalContent>
    </Modal>
  );
}
