import { useQuery } from '@tanstack/react-query';
import { getTags } from '../../services/TagService';
import log from '../../utils/Logger';
import { useAppState } from '../../store/appState';
import TagStart from './TagStart';
import { doFilterByTags } from '../../utils/Filter';
import { getCollection } from '../../services/CollectionService';

const MIN_NUMBER_OF_IMAGES_MATCHING = 1;

export default function TagsListStart() {
  const query = useQuery({
    queryKey: ['tags'],
    queryFn: getTags,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });
  const collectionQuery = useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });
  const selectedTags = useAppState((state) => state.selectedTags);
  const setSelectedTags = useAppState((state) => state.setSelectedTags);

  const toggleTag = (tag) => {
    log.debug('toggle tag called for: ', tag);
    const newSelectedTags = selectedTags.some((x) => x.id === tag.id)
      ? selectedTags.filter((i) => i.id !== tag.id)
      : [...selectedTags, tag];
    setSelectedTags(newSelectedTags);
  };

  const isTagDisabled = (tag) => {
    const filteredTags = doFilterByTags(
      [...selectedTags, tag],
      collectionQuery.data?.collection?.collectible
    );
    return filteredTags?.length < MIN_NUMBER_OF_IMAGES_MATCHING;
  };

  return (
    <div className="h-3/4 pt-10 px-4 w-full ">
      <div className="grid grid-cols-5 gap-4 grid-flow-row justify-items-center">
        {query.data &&
          query.data.map((tag) => {
            return tag.groupNumber == 0 ? (
              <TagStart
                key={tag.id}
                tag={tag}
                selected={selectedTags.some((x) => x.id == tag.id)}
                toggleTag={toggleTag}
                showXOnSelected={true}
              />
            ) : (
              ''
            );
          })}
      </div>
      <div className="pt-8 grid grid-cols-5 gap-4 grid-flow-row justify-items-center">
        {query.data &&
          query.data.map((tag) => {
            return tag.groupNumber != 0 ? (
              <TagStart
                key={tag.id}
                tag={tag}
                selected={selectedTags.some((x) => x.id == tag.id)}
                toggleTag={toggleTag}
                showXOnSelected={true}
              />
            ) : (
              ''
            );
          })}
      </div>
    </div>
  );
}
