import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import log from './utils/Logger';
import { IDLE_TIME } from './AppConfig';
import { useAppState } from './store/appState';
import AnimatedRoutes from './components/AnimatedRoutes';

const queryClient = new QueryClient();

function App() {
  const resetState = useAppState((state) => state.restart);
  const setShowMapButton = useAppState((state) => state.setShowMapButton);
  const navigate = useNavigate();

  const onIdle = () => {
    const pathname = window.location.pathname;
    if (pathname.includes('projectorview')) return;
    // TODO: maybe show warning before resetting
    log.debug('user idle called');
    resetState();
    setShowMapButton(false);
    navigate('/');
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    log.debug('user active called');
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: IDLE_TIME,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AnimatedRoutes />
      </QueryClientProvider>
    </div>
  );
}

export default App;
