import { REACT_APP_APISERVER, WALL_NAME } from '../AppConfig';
import { GetRandomPoints } from './RandomPoints';
import log from '../utils/Logger';
import { createExhibition } from './ExhibitionGenerator';
import {
  editExhibition,
  getTempExhibitionId,
  saveExhibition,
  setActiveExhibition
} from '../services/ExhibitionService';
import { stopDefaultExhibitionLoop } from './DefaultExhibitionLoop';

export const toggleImagePlaced = async (collectible, placedImages, setPlacedImages) => {
  let newPlacedImages;
  const placedImage = placedImages.find((x) => x.collectibleId == collectible.id);
  if (placedImage) {
    newPlacedImages = placedImages.filter((x) => x.collectibleId != collectible.id);
    setPlacedImages(newPlacedImages);
  } else {
    const [x, y] = GetRandomPoints(collectible);
    newPlacedImages = placedImages.concat([
      {
        x,
        y,
        src: REACT_APP_APISERVER + collectible?.previewMedia?.url,
        collectibleId: collectible.id,
        collectibleWidth: collectible.width,
        collectibleHeight: collectible.height
      }
    ]);
    setPlacedImages(newPlacedImages);
  }
  await saveAsTempExhibition(newPlacedImages);
  return newPlacedImages;
};

const saveAsTempExhibition = async (newPlacedImages) => {
  log.debug('save as temp exhibition called');
  const exhibition = createExhibition(newPlacedImages, null, 'tmp', WALL_NAME);
  const tempExhibitionId = getTempExhibitionId();
  if (tempExhibitionId < 0) {
    await saveExhibition(exhibition);
    await setActiveExhibition(getTempExhibitionId());
    stopDefaultExhibitionLoop();
  } else {
    const res = await editExhibition(tempExhibitionId, exhibition, false);
  }
};
