import { useAppState } from '../store/appState';

export default function FooterButtons({ children, classNameAddon }) {
  const inputFocused = useAppState((state) => state.inputFocused);
  return !inputFocused ? (
    <div className="absolute bottom-6 left-0 w-full ">
      <div className={'justify-center flex w-full ' + classNameAddon}>{children}</div>
    </div>
  ) : (
    ''
  );
}
