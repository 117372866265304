import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../components/Button/DefaultButton';
import FooterButtons from '../components/FooterButtons';
import ImageStackCard from '../components/ImageSelection/ImageStackCard';
import { Page } from '../components/Page/Page';
import { getCollection } from '../services/CollectionService';
import { useAppState } from '../store/appState';
import { doFilterByTags, sortByMostMatchedTags } from '../utils/Filter';
import log from '../utils/Logger';
import { toggleImagePlaced } from '../utils/ToggleImagePlaced';

export default function ImageSelectionStack() {
  const navigate = useNavigate();
  const query = useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });
  const selectedTags = useAppState((state) => state.selectedTags);
  const setSelectedTags = useAppState((state) => state.setSelectedTags);
  const [imagesToShow, setImagesToShow] = useState([]);
  const placedImages = useAppState((state) => state.placedImages);
  const setPlacedImages = useAppState((state) => state.setPlacedImages);
  const addRejectedImage = useAppState((state) => state.addRejectedImage);

  const onImageClicked = async (collectible, confirmed) => {
    if (confirmed) {
      toggleImagePlaced(collectible, placedImages, setPlacedImages);
    } else {
      addRejectedImage(collectible);
    }
    const updatedImagesToShow = [...imagesToShow];
    updatedImagesToShow.find((x) => x.id == collectible.id).isHidden = true;
    setImagesToShow(updatedImagesToShow);
  };

  const goToMain = () => {
    setSelectedTags([]);
    navigate('/main');
  };

  useEffect(() => {
    if (query.data && imagesToShow?.length === 0) {
      const filteredImages = doFilterByTags(
        selectedTags,
        query.data?.collection?.collectible,
        true
      );
      log.debug('filteredImages: ', filteredImages);
      if (filteredImages.length === 0) {
        setImagesToShow(query.data?.collection?.collectible);
      } else {
        setImagesToShow(filteredImages.reverse());
      }
      log.debug('bla filteredImages: ', filteredImages);
    }
  }, [query]);

  // as soon as all images clicked (hidden) go to main page
  useEffect(() => {
    if (imagesToShow?.length > 0 && !imagesToShow.some((x) => !x.isHidden)) {
      goToMain();
    }
  }, [imagesToShow]);

  return (
    <Page bg="bg-aarau" classAddon="grid">
      <div id="imageselectionstack" className="flex relative justify-center w-full">
        {imagesToShow &&
          imagesToShow.map((c, index) => {
            return (
              <ImageStackCard
                key={c?.id}
                collectible={c}
                index={index}
                onClickImage={onImageClicked}
              />
            );
          })}
      </div>
      <FooterButtons classNameAddon={'justify-center'}>
        <DefaultButton
          text="WEITER"
          onButtonClicked={goToMain}
          hidden={placedImages?.length === 0}
        />
      </FooterButtons>
    </Page>
  );
}
