import create from 'zustand';

export const useAppState = create((set) => ({
  selectedTags: [],
  placedImages: [],
  rejectedImages: [],
  adminMode: 0,
  open: false,
  addTag: (tag) => set((state) => ({ selectedTags: [...state.selectedTags, tag] })),
  setSelectedTags: (tags) => set({ selectedTags: tags }),
  removeTag: (tagId) =>
    set((state) => ({ selectedTags: state.selectedTags.filter((tag) => tag.id != tagId) })),
  addImage: (image) => set((state) => ({ placedImages: [...state.placedImages, image] })),
  removeImage: (imageId) =>
    set((state) => ({ placedImages: state.placedImages.filter((image) => image.id != imageId) })),
  setPlacedImages: (images) => set({ placedImages: images }),
  addRejectedImage: (image) =>
    set((state) => ({ rejectedImages: [...state.rejectedImages, image] })),
  removeRejectedImage: (imageId) =>
    set((state) => ({
      rejectedImages: state.rejectedImages.filter((image) => image.id != imageId)
    })),
  resetTags: () => set({ selectedTags: [] }),
  resetImages: () => set({ placedImages: [] }),
  restart: () =>
    set({
      selectedTags: [],
      placedImages: [],
      onboardingShown: 0,
      showSaveButton: false,
      open: false
    }),
  setAdminMode: (mode) => set({ adminMode: mode }),
  setOpen: (state) => set({ open: state }),
  onboardingShown: 0,
  setOnboardingShown: (setShown) => set({ onboardingShown: setShown }),
  showSaveButton: false,
  setShowSaveButton: (show) => set({ showSaveButton: show }),
  showMapButton: false,
  setShowMapButton: (show) => set({ showMapButton: show }),
  inputFocused: false,
  setInputFocused: (isFocused) => set({ inputFocused: isFocused })
}));
