import { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { getRandomPadding } from '../../utils/RandomPadding';
import { getTagColorClass } from '../../utils/TagColor';

export default function TagStart({ tag, selected, toggleTag, showXOnSelected }) {
  const [randomPadding] = useState(() => getRandomPadding());

  const classNameAddon = getTagColorClass(tag, selected);
  return (
    <div className={'relative ' + randomPadding}>
      <div
        className={
          ' px-6 py-2 text-xl hover:cursor-pointer rounded-full transition transform active:scale-105 flex flex-none ' +
          classNameAddon
        }
        onClick={() => {
          toggleTag(tag, !selected);
        }}>
        {tag.name.de}
        {selected && showXOnSelected && (
          <IoCloseSharp className="ml-3 inline text-white place-self-center " />
        )}
      </div>
    </div>
  );
}
