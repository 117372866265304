import { FILTER_BY_PLACED_ID } from '../AppConfig';
import log from '../utils/Logger';

const NUMBER_OF_IMAGES = 10;

export function doFilterByTextAndTags(
  searchText,
  tags,
  elementsToFilter,
  accumulate,
  placedImages
) {
  const filteredElementsByText = filterByText(searchText, elementsToFilter);
  const filteredByTextAndTags = filterByTags(
    tags,
    filteredElementsByText,
    accumulate,
    placedImages
  );
  return filteredByTextAndTags;
}

// returns a fixed amount of images for stacked view
export function doFilterByTags(tags, elementsToFilter, accumulate) {
  const filteredByTags = filterByTags(tags, elementsToFilter, accumulate);
  return filteredByTags?.slice(0, NUMBER_OF_IMAGES);
  //return filteredByTags;
}

// needed for nested objects due to multilang fields
const isObject = (el) => {
  return typeof el === 'object' && !(el instanceof String) && el !== null;
};

export function filterByText(searchText, elementsToFilter) {
  if (!searchText) {
    return elementsToFilter;
  }
  const res = elementsToFilter.filter((element) => {
    return Object.keys(element).reduce((ret, elKey) => {
      const el = element[elKey];
      return (
        ret ||
        (typeof el === 'string' &&
          el.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) ||
        (isObject(el) &&
          JSON.stringify(el).toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
      );
    }, false);
  });
  return res;
}

// checks if array contains all elements of target array (by id of element)
const checker = (arr, target) => target.every((v) => arr.some((x) => v.id == x.id));
const checkerAccumulated = (arr, target) => target.some((v) => arr.some((x) => v.id == x.id));
const checkerSum = (arr, target) => {
  const tmpArray = target.map((v) => arr.filter((x) => v.id == x.id).length);
  return tmpArray.reduce((a, b) => a + b, 0);
};

const filterElementsByPlacedSet = (elementsToFilter, placedImages) => {
  if (placedImages) {
    return elementsToFilter.filter((x) => placedImages.some((y) => x.id === y.collectibleId));
  }
  return elementsToFilter;
};

const filterByTags = (tags, elementsToFilter, accumulate, placedImages) => {
  const actualTags = tags.filter((t) => t.id !== FILTER_BY_PLACED_ID);
  const filterByPlacedSet = tags.some((x) => x.id === FILTER_BY_PLACED_ID);
  const elementsPlaced = filterByPlacedSet
    ? filterElementsByPlacedSet(elementsToFilter, placedImages)
    : [];
  if (actualTags && actualTags.length > 0 && elementsToFilter && elementsToFilter.length > 0) {
    if (accumulate) {
      const accumulatedElements = elementsToFilter.filter((el) =>
        checkerAccumulated(el.tags, actualTags)
      );
      if (elementsPlaced.length > 0) {
        log.debug('bla ok filter by place set, ', elementsPlaced);
        elementsPlaced.map((placed) => {
          if (accumulatedElements.findIndex((x) => x.id === placed.id)) {
            log.debug('bla ok pushed placed element, ', placed);
            accumulatedElements.push(placed);
          } else {
            log.debug('bla ok found placed element, ', placed);
          }
        });
      }
      return sortByMostMatchedTags(accumulatedElements, actualTags);
    } else {
      return elementsPlaced.filter((el) => checker(el.tags, actualTags));
    }
  } else {
    return elementsPlaced || elementsToFilter;
  }
};

export const sortByMostMatchedTags = (elementsToSort, tags) => {
  return elementsToSort.sort(function (x, y) {
    if (checkerSum(x.tags, tags) < checkerSum(y.tags, tags)) {
      return 1;
    }
    if (checkerSum(x.tags, tags) > checkerSum(y.tags, tags)) {
      return -1;
    }
    return 0;
  });
};
