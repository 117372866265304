export const getTagColorClass = (tag, active) => {
  switch (tag.groupNumber) {
    case 0:
      return active ? 'bg-tagDefaultActive text-white' : 'bg-tagDefault';
    case 1:
      return active ? 'bg-tagDateActive  text-white' : 'bg-tagDate';
    case 2:
      return active ? 'bg-tagTechniqueActive  text-white' : 'bg-tagTechnique';
    case 7:
      return active ? 'bg-tagCurrentSelectionActive  text-white' : 'bg-tagCurrentSelection';

    default:
      return active ? 'bg-tagDefaultActive text-white' : 'bg-tagDefault';
  }
};
