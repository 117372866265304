import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ExhibitionSelection from '../pages/ExhibitionSelection';
import ImageSelectionClassic from '../pages/ImageSelectionClassic';
import ImageSelectionStack from '../pages/ImageSelectionStack';
import Main from '../pages/Main';
import ProjectorView from '../pages/ProjectorView';
import Start from '../pages/Start';
import TagSelection from '../pages/TagSelection';

export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    // <AnimatePresence initial={false}>
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Start />} />
      <Route path="/tagselection" element={<TagSelection />} />
      <Route path="/imageselection-stack" element={<ImageSelectionStack />} />
      <Route path="/main" element={<Main />} />
      <Route path="/projectorview" element={<ProjectorView />} />
    </Routes>
    // </AnimatePresence>
  );
}
