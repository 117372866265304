import Search from './Search/Search';
import TagsList from './Tags/TagsList';

export default function Header({ searchText, filterBySearch, classNameAddon, showTags }) {
  const classBoder = showTags ? ' border-y-2 border-black bg-white ' : '';
  const classBorderButton = !showTags ? ' border-y-2 border-black ' : '';

  return (
    <div className={'pt-16 w-full z-10 ' + classNameAddon}>
      <div className={'grid grid-cols-4 gap-4 ' + classBoder}>
        <div className={'col-span-1 flex place-content-center ' + classBorderButton}>
          <Search searchText={searchText} setSearchText={filterBySearch} />
        </div>
        {showTags && (
          <div className="col-span-3 flex place-content-left place-items-center my-2 ">
            <TagsList />
          </div>
        )}
      </div>
    </div>
  );
}
