import { React } from 'react';
import DefaultButton from './DefaultButton';

export default function AdminModeButton({ adminMode, onButtonClicked }) {
  return (
    <div
      className="absolute left-0 top-0 h-16 w-16"
      onClick={() => {
        onButtonClicked?.();
      }}>
      {adminMode == 1 ? (
        <DefaultButton text="Login" hidden={false} />
      ) : adminMode == 2 ? (
        <DefaultButton text="Logout" hidden={false} />
      ) : (
        ''
      )}
    </div>
  );
}
