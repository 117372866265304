//export const REACT_APP_APISERVER = 'http://localhost:3001';
//export const REACT_APP_APISERVER = 'https://192.168.77.7';
export const REACT_APP_APISERVER = 'https://dev-varcu-backend-stadtaarau.jcloud.ik-server.com';
//export const REACT_APP_APISERVER = 'https://dev-varcu-backend.jcloud.ik-server.com';
//export const REACT_APP_APISERVER = 'https://prod-backend.varcu.app';

export const COLLECTION_ID = 656; // 656 for dev server, 8 for localhost
export const CUSTOMER_ID = 20; // 20 for dev server, 1 for localhost

export const USERNAME = 'stadtaarau'; // 'stadtaarau' for dev server // localhost: 'stt'
export const PASSWORD = 'tst'; // 'tst for dev server

export const CANVAS_WIDTH_CM = 250;
export const CANVAS_HEIGHT_CM = 156.25;
export const CANVAS_CM_TO_PIXEL_RATIO = 3.9;

export let WALL_NAME = 'WandLinks'; // set to 'WandLinks' or 'WandRechts'. can be dynamically set via query string 'wall'

export const IDLE_TIME = 1000 * 60 * 5; // in milliseconds, 5min: 1000 * 60 * 5;
export const EXHIBITIONS_INTERVAL_TIME = 1000 * 60 * 1; // in milliseconds, 1min: 1000 * 60 * 1;

export const setWallName = (wallName) => {
  WALL_NAME = wallName;
};

export const FILTER_BY_PLACED_ID = 1337;
