import { Stage, Layer, Rect, Tag, Label, Text } from 'react-konva';
import {
  CANVAS_CM_TO_PIXEL_RATIO,
  CANVAS_HEIGHT_CM,
  CANVAS_WIDTH_CM,
  setWallName,
  WALL_NAME
} from '../../AppConfig';
import { URLImage } from './URLImage';
import log from '../../utils/Logger';
import { createExhibition } from '../../utils/ExhibitionGenerator';
import {
  editExhibition,
  getTempExhibitionId,
  saveExhibition
} from '../../services/ExhibitionService';
import Konva from 'konva';
import { useEffect, useRef, useState } from 'react';
import { useAppState } from '../../store/appState';
import { useQuery } from '@tanstack/react-query';
import { getCollection } from '../../services/CollectionService';

export const Canvas = ({
  onDrop,
  placedImages,
  setPlacedImages,
  stageRef,
  className,
  readonly,
  setShowBin,
  children
}) => {
  const [animation, setAnimation] = useState(undefined);
  const [animationTooltip, setAnimationTooltip] = useState(undefined);
  const [imageText, setImageText] = useState('');
  const [imageText2, setImageText2] = useState('');

  const onboardingShown = useAppState((state) => state.onboardingShown);
  const setOnboardingShown = useAppState((state) => state.setOnboardingShown);
  const adminMode = useAppState((state) => state.adminMode);
  const setShowSaveButton = useAppState((state) => state.setShowSaveButton);
  const defaultLayerRef = useRef();

  const query = useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });

  useEffect(() => {
    log.debug('useEffect placedImages called');
    if (placedImages?.length > 0 && onboardingShown < 2 && adminMode === 0 && !readonly) {
      log.debug('placedImages: ', placedImages);
      const el = stageRef.current.findOne(`#Placed_${placedImages.at(-1).collectibleId}`);
      log.debug('placedImage: ', el);
      if (el) {
        setOnboardingAnimation(el);
      }
      setOnboardingShown(onboardingShown + 1);
    }
    // reset image text when placedImages changed
    setImageText('');
  }, [placedImages]);

  const searchParam = new URLSearchParams(document.location.search);
  if (searchParam.get('wall')) {
    setWallName(searchParam.get('wall'));
    log.debug('wallName set To: ', WALL_NAME);
  }
  const changeImage = (collectibleId, posX, posY, scale, remove) => {
    if (readonly) return;
    setShowSaveButton(true);

    const changedImage = placedImages.find((x) => x.collectibleId == collectibleId);
    if (changedImage) {
      log.debug('changedImage: ', changedImage);
      changedImage.x = posX;
      changedImage.y = posY;
      changedImage.scale = scale || 1;
      const newPlacedImages = remove
        ? placedImages.filter((x) => x.collectibleId != collectibleId)
        : placedImages;
      setPlacedImages(newPlacedImages);

      const exhibition = createExhibition(newPlacedImages, null, 'tmp', WALL_NAME);
      const tempExhibitionId = getTempExhibitionId();
      if (tempExhibitionId > 0) {
        editExhibition(tempExhibitionId, exhibition, false);
      } else {
        saveExhibition(exhibition);
      }
    }
  };

  const canvasWidth = CANVAS_WIDTH_CM * CANVAS_CM_TO_PIXEL_RATIO;
  const canvasHeight = CANVAS_HEIGHT_CM * CANVAS_CM_TO_PIXEL_RATIO;

  const getOnboardingPosition = (element) => {
    const x = Math.min(Math.max(element.attrs.x - 140, 5), canvasWidth - 320);
    const y = Math.min(element.attrs.y + 70, canvasHeight - 35);
    return [x, y];
  };

  const setOnboardingAnimation = (element) => {
    log.debug('setOnboardingAnimation called, ', onboardingShown);

    if (onboardingShown == 1) {
      var simpleLabel = new Konva.Label({
        x: 250,
        y: 10,
        opacity: 0.75
      });

      simpleLabel.add(
        new Konva.Tag({
          fill: 'white'
        })
      );

      simpleLabel.add(
        new Konva.Text({
          text: 'Schau dir deine Auswahl an der Wand an!',
          fontFamily: 'Avenir',
          fontSize: 24,
          padding: 5,
          fill: 'black'
        })
      );

      defaultLayerRef.current.add(simpleLabel);
      setAnimationTooltip(simpleLabel);
      setOnboardingShown(2);
      setTimeout(() => {
        if (simpleLabel) {
          simpleLabel.destroy();
        }
      }, 5000);
    } else if (onboardingShown == 0) {
      const [x, y] = getOnboardingPosition(element);
      var simpleLabel2 = new Konva.Label({
        x,
        y,
        opacity: 0.75
      });

      simpleLabel2.add(
        new Konva.Tag({
          fill: 'white'
        })
      );

      simpleLabel2.add(
        new Konva.Text({
          text: 'Verschiebe und vergrössere die Bilder!',
          fontFamily: 'Avenir',
          fontSize: 18,
          padding: 5,
          fill: 'black'
        })
      );

      defaultLayerRef.current.add(simpleLabel2);

      var amplitude = 20;
      var period = 2000;
      // in ms
      var centerX = element.attrs.x;

      var anim = new Konva.Animation(function (frame) {
        element.x(amplitude * Math.sin((frame.time * 2 * Math.PI) / period) + centerX);
      }, defaultLayerRef.current);

      anim.start();
      setAnimation(anim);
      setAnimationTooltip(simpleLabel2);
    }
  };

  const stopOnboardingAnimation = () => {
    if (animationTooltip) {
      animationTooltip.destroy();
    }
    if (animation) {
      animation.stop();
    }
    if (onboardingShown < 2) {
      setOnboardingAnimation();
      setOnboardingShown(2);
    }
  };

  const onClickAnimation = (element, initialScale) => {
    var period = 500;

    var anim = new Konva.Animation(function (frame) {
      var scale = initialScale + Math.sin((frame.time * 2 * Math.PI) / period) / 8;
      element.scale({ x: scale, y: scale });
    }, defaultLayerRef.current);

    anim.start();
    setTimeout(() => anim.stop(), 250);
  };

  const onImageClick = (collectibleId) => {
    if (!collectibleId) {
      setImageText('');
    } else {
      const imageToShow = query.data?.collection?.collectible.find((x) => x.id == collectibleId);
      log.debug('ok set text for, ', imageToShow);
      const newText = imageToShow?.title?.de;
      const newText2 = imageToShow?.date?.de
        ? imageToShow?.artist?.de + ', ' + imageToShow.date.de
        : imageToShow?.artist?.de;
      if (newText != imageText) {
        setImageText(newText);
        setImageText2(newText2);
      } else {
        setImageText('');
        setImageText2('');
      }
    }
  };

  return (
    // Stage - is a div wrapper
    // Layer - is an actual 2d canvas element, so you can have several layers inside the stage
    // Rect and Circle are not DOM elements. They are 2d shapes on canvas
    <div id="canvas" className={className} onDrop={onDrop} onDragOver={(e) => e.preventDefault()}>
      <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
        <Layer ref={defaultLayerRef}>
          <Rect
            x={0}
            y={0}
            width={canvasWidth}
            height={canvasHeight}
            fill="white"
            onTouchStart={() => onImageClick(null)}
          />
          {placedImages.map((image) => {
            return (
              <URLImage
                image={image}
                key={image.collectibleId}
                collectibleId={image.collectibleId}
                onChange={changeImage}
                readonly={readonly}
                onClickAnimation={onClickAnimation}
                stopOnboardingAnimation={stopOnboardingAnimation}
                setShowBin={setShowBin}
                onImageClick={onImageClick}
              />
            );
          })}
          {!readonly && imageText && (
            <Label opacity={0.75} x={0} y={canvasHeight - 50}>
              <Tag fill="#FFFFFF" width={canvasWidth} height={100} />
              <Text
                text={imageText}
                fontFamily="Avenir"
                fontSize={14}
                fontStyle="bold"
                padding={10}
                fill="black"
                width={canvasWidth}
                height={100}
              />
              <Text
                text={imageText2}
                fontFamily="Avenir"
                fontSize={14}
                padding={10}
                offsetY={-20}
                fill="black"
                width={canvasWidth}
              />
            </Label>
          )}
        </Layer>
      </Stage>
      {children}
    </div>
  );
};
