import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setWallName } from '../AppConfig';
import DefaultButton from '../components/Button/DefaultButton';
import { Page } from '../components/Page/Page';
import { getCollection } from '../services/CollectionService';
import { getExhibitions } from '../services/ExhibitionService';
import { getTags } from '../services/TagService';
import { startDefaultExhibitionLoop } from '../utils/DefaultExhibitionLoop';
import log from '../utils/Logger';

export default function Start() {
  // load images, tags and exhibitions on start
  useQuery({
    queryKey: ['collection'],
    queryFn: getCollection,
    cacheTime: 24 * 60 * 60 * 1000
  });
  useQuery({
    queryKey: ['tags'],
    queryFn: getTags,
    cacheTime: 24 * 60 * 60 * 1000
  });
  const exhibitionsQuery = useQuery({
    queryKey: ['exhibitions'],
    queryFn: getExhibitions,
    cacheTime: 24 * 60 * 60 * 1000
  });

  const searchParam = new URLSearchParams(document.location.search);
  if (searchParam.get('wall')) {
    setWallName(searchParam.get('wall'));
    log.debug('wallName set To: ', searchParam.get('wall'));
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (exhibitionsQuery?.data) {
      log.debug('use effect on start called');
      startDefaultExhibitionLoop(exhibitionsQuery?.data);
    }
  }, [exhibitionsQuery]);

  return (
    <Page bg="bg-aarau" classAddon="grid">
      <div id="start" className="h-full flex justify-center place-items-center">
        <DefaultButton
          text="START"
          onButtonClicked={() => {
            navigate('/tagselection');
          }}
          size="boldwide"
        />
      </div>
    </Page>
  );
}
