import { React, useState } from 'react';
import { delay } from '../../utils/Delay';

export default function DefaultButton({
  text,
  onButtonClicked,
  className = '',
  hidden,
  size = 'standard',
  filled = true
}) {
  const [effect, setEffect] = useState(false);

  if (size == 'standard') className += ' w-48 h-16 text-3xl ';
  if (size == 'thin') className += ' w-48 h-8 text-xl ';
  if (size == 'bold') className += ' h-20 text-3xl ';
  if (size == 'boldwide') className += ' w-96 h-20 text-4xl ';
  if (size == 'wide') className += ' w-96 h-12 text-3xl ';

  filled
    ? (className +=
        ' bg-black text-white border-white hover:cursor-pointer transition transform active:scale-105 ')
    : (className += ' bg-white text-black border-black hover:cursor-default');

  console.log('default button disabled: ', hidden);
  if (hidden) className += ' invisible';

  return (
    <div
      className={
        `${
          effect && 'animate-scaleit'
        } border-y-2 py-2 px-6 flex justify-center place-items-center transition-all duration-500 ${
          hidden ? 'opacity-0' : 'opacity-100'
        } ` + className
      }
      onClick={async () => {
        if (!hidden) {
          setEffect(true);
          await delay(200);
          onButtonClicked?.();
        }
      }}
      onAnimationEnd={() => setEffect(false)}>
      {text}
    </div>
  );
}
