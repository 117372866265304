import { useQuery } from '@tanstack/react-query';
import { getTags } from '../../services/TagService';
import Tag from './Tag';
import log from '../../utils/Logger';
import { useAppState } from '../../store/appState';
import { useState } from 'react';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import { FILTER_BY_PLACED_ID } from '../../AppConfig';

export default function TagsList() {
  const query = useQuery({
    queryKey: ['tags'],
    queryFn: getTags,
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000
  });
  const selectedTags = useAppState((state) => state.selectedTags);
  const setSelectedTags = useAppState((state) => state.setSelectedTags);
  const setOpen = useAppState((state) => state.setOpen);
  const open = useAppState((state) => state.open);
  const [filterByPlaced, setFilterByPlaced] = useState(false);

  const toggleTag = (tag) => {
    const newSelectedTags = selectedTags.some((x) => x.id === tag.id)
      ? selectedTags.filter((i) => i.id !== tag.id)
      : [...selectedTags, tag];
    setSelectedTags(newSelectedTags);
    setOpen(true);
  };

  const toggleFilterByPlaced = () => {
    toggleTag(filterByPlacedTag);
    setFilterByPlaced(!filterByPlaced);
  };

  const filterByPlacedTag = {
    id: FILTER_BY_PLACED_ID,
    name: { de: 'Auswahl' },
    groupNumber: 7
  };

  function isSelected(tag) {
    return selectedTags.some((x) => x.id == tag.id);
  }

  function TagRow(activetags) {
    return (
      <div className="col-span-3 overflow-x-auto flex scrollbar-hide">
        {activetags.activetags ? (
          <div
            className=" text-3xl pr-2 flex place-content-center place-items-center hover:cursor-pointer transition transform active:scale-105"
            onClick={() => {
              setOpen(!open);
            }}>
            {open ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
          </div>
        ) : (
          ''
        )}
        {(activetags.activetags && isSelected(filterByPlacedTag)) ||
        (!activetags.activetags && !isSelected(filterByPlacedTag)) ? (
          <Tag
            key={filterByPlacedTag.id}
            tag={filterByPlacedTag}
            selected={isSelected(filterByPlacedTag)}
            toggleTag={toggleFilterByPlaced}
            showXOnSelected={true}
          />
        ) : (
          ''
        )}
        {query.data &&
          query.data.map((tag) => {
            const whichtags = activetags.activetags ? isSelected(tag) : !isSelected(tag);
            return whichtags ? (
              <Tag
                key={tag.id}
                tag={tag}
                selected={isSelected(tag)}
                toggleTag={toggleTag}
                showXOnSelected={true}
              />
            ) : (
              ''
            );
          })}
      </div>
    );
  }

  return (
    <div
      tabIndex={0}
      onBlur={() => {
        setOpen(false);
      }}
      className="overflow-x-auto flex flex-col gap-2 ">
      {open || selectedTags.length > 0 ? <TagRow activetags={true}></TagRow> : ''}
      {open || selectedTags.length == 0 ? <TagRow activetags={false}></TagRow> : ''}
    </div>
  );
}
