import { postToApi, setTokens, setUser } from './APIService';
import jwt from 'jwt-decode'; // import dependency
import { PASSWORD, USERNAME } from '../AppConfig';

let alreadySent = false;

export async function login(username = USERNAME, password = PASSWORD) {
  if (alreadySent) return;
  const res = await postToApi('login', {
    username,
    password
  });
  alreadySent = true;
  if (res && res.accessToken && res.refreshToken) {
    setTokens(res.accessToken, res.refreshToken);
    const user = jwt(res.accessToken);
    setUser(user);
    alreadySent = false;
    return { ...res, user: user };
  }
  alreadySent = false;
  return false;
  //return undefined;
  //console.log("login service done with data", res);
}
