import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, memo } from 'react';
import { areEqual, FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import { useAppState } from '../../store/appState';
import ListCard from '../ListCard';
import { listcardAnimationProps } from '../../utils/Animation';

const ImageSelectionCell = memo(({ columnIndex, rowIndex, style, data }) => {
  const { onImageClick, placedImages, onClick, items } = data;
  const COLUMNS = 5;
  const CELL_GAP = 4;
  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : Number(style.left) + columnIndex * CELL_GAP,
    right:
      columnIndex === COLUMNS ? style.right : Number(style.right) + columnIndex * CELL_GAP || 0,
    top: rowIndex === 0 ? style.top : Number(style.top) + rowIndex * CELL_GAP
  };
  const index = rowIndex * COLUMNS + columnIndex;
  const item = items[index];
  const content = (
    <>
      <div className="font-bold">{item?.title?.de}</div>
      <div>{item?.artist?.de + ', ' + item?.date?.de}</div>
    </>
  );
  return item ? (
    <motion.div
      key={`${item.id}-card`}
      {...listcardAnimationProps}
      style={styles}
      className="pb-4 pr-4">
      <ListCard
        data={item}
        image={item?.thumbnailMedia?.url}
        content={content}
        onClick={onClick}
        onImageClick={onImageClick}
        showCircles={true}
        isPlaced={placedImages.some((x) => x.collectibleId == item?.id)}
        dropShadow={true}
      />
    </motion.div>
  ) : (
    ''
  );
}, areEqual);
ImageSelectionCell.displayName = 'ImageSelectionCell';

const getItemData = memoizeOne((onClick, onImageClick, placedImages, items) => ({
  onClick,
  onImageClick,
  placedImages,
  items
}));

export default function ImageSelection({ filteredImages, onClick, onImageClick, placedImages }) {
  const open = useAppState((state) => state.open);
  const listposition = open ? ' pt-10 ' : '';
  const COLUMNS = 5;

  const itemData = getItemData(onClick, onImageClick, placedImages, filteredImages);

  const getItemKey = (rowIndex, columnIndex, data) => {
    const index = rowIndex * COLUMNS + columnIndex;
    return data.items[index]?.id + '-griditem-' + columnIndex;
  };

  return (
    <div
      id="image-selection"
      className={
        'h-[73%] w-full flex justify-items-start content-start gap-4 pl-6 flex-row px-4 flex-wrap overflow-y-auto scrollbar-hide ' +
        listposition
      }>
      {filteredImages && filteredImages.length > 0 ? (
        <AnimatePresence initial={false}>
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                columnCount={COLUMNS}
                columnWidth={209}
                height={height}
                rowCount={Math.ceil(filteredImages.length / COLUMNS)}
                rowHeight={298}
                width={width}
                itemKey={(data) => getItemKey(data.rowIndex, data.columnIndex, data.data)}
                itemData={itemData}>
                {ImageSelectionCell}
              </Grid>
            )}
          </AutoSizer>
        </AnimatePresence>
      ) : (
        <div className="ml-4">Keine Bilder gefunden. Bitte Tagauswahl / Suche anpassen.</div>
      )}
    </div>
  );
}
