import { getFromApi } from './APIService';
import log from '../utils/Logger';
import { CUSTOMER_ID } from '../AppConfig';
import { login } from './LoginService';

export async function getTags() {
  let res = await getFromApi(`customer/${CUSTOMER_ID}/tags`, {});
  log.debug('getTags', res);
  return res;
}
