import Tag from './Tag';
import log from '../../utils/Logger';
import { useAppState } from '../../store/appState';

export default function TagsListImage({ tags }) {
  const selectedTags = useAppState((state) => state.selectedTags);
  return (
    <div className="flex flex-wrap gap-y-2 justify-self-start ">
      {tags.map((tag) => {
        return selectedTags.some((x) => tag.id == x.id) ? (
          <Tag key={tag.id} tag={tag} selected={true} showXOnSelected={false} />
        ) : (
          ''
        );
      })}
    </div>
  );
}
