import { CANVAS_CM_TO_PIXEL_RATIO, COLLECTION_ID } from '../AppConfig';
import log from '../utils/Logger';

export function createExhibition(images, mediaId, title, author) {
  log.debug('images: ', images);
  const exhibition = {
    title,
    author,
    collectionId: COLLECTION_ID,
    mediaId,
    exhibitionObjects: [],
    exhibitionPictures: []
  };
  if (!mediaId) {
    delete exhibition.mediaId;
  }
  images?.map((i) => {
    const picture = {
      position: [i.x / CANVAS_CM_TO_PIXEL_RATIO, i.y / CANVAS_CM_TO_PIXEL_RATIO],
      rotation: [i.scale],
      collectibleId: i.collectibleId
    };
    exhibition.exhibitionPictures.push(picture);
  });
  return exhibition;
}
