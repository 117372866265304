/* eslint-disable no-useless-escape */
import { React, useState } from 'react';
import { useAppState } from '../../store/appState';
import log from '../../utils/Logger';

export default function InputGeneral({
  placeholder,
  onChange,
  onKeyUp,
  onBlur,
  onFocus,
  value = '',
  disabled = false,
  step = '0.1',
  type = 'text',
  className = 'text-black placeholder-gray px-4 border-y-2 border-black w-72 h-12',
  classNameAddon,
  required = false
}) {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const setInputFocused = useAppState((state) => state.setInputFocused);

  const validateRequired = (value) => {
    if (!value || /^\s*$/.test(value)) {
      setErrorMessage('Dieses Feld darf nicht leer sein');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <>
      <input
        enterKeyHint="done"
        onChange={(event) => {
          onChange && onChange(event.target.value);
        }}
        onKeyUp={(event) => {
          if (event.keyCode === 13) {
            log.debug('enter clicked');
            onKeyUp && onKeyUp(event);
          }
          if (required) {
            validateRequired(event.target.value);
          }
        }}
        onBlur={(event) => {
          onBlur && !errorMessage && onBlur(event.target.value);
          setInputFocused(false);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onFocus={() => {
          onFocus?.();
          setInputFocused(true);
        }}
        type={type}
        step={step}
        disabled={disabled}
        value={value != null ? value : ''}
        placeholder={placeholder}
        className={className + ' ' + classNameAddon}
      />
      {errorMessage ? (
        <div className="text-xs px-2 text-white bg-red">{errorMessage}</div>
      ) : (
        <div></div>
      )}
    </>
  );
}
