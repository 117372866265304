export const listcardAnimationProps = {
  animate: { x: 0, opacity: 1 },
  initial: { x: 0, opacity: 0 },
  exit: { x: 0, opacity: 0 },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    repeat: false,
    duration: 0.5
  }
};

// TODO: add animation bottom up, doesn't seem possible with fixed modal see https://github.com/framer/motion/issues/1117
export const bottomUpAnimationProps = {
  animate: { y: 0, opacity: 1 },
  initial: { y: 1000, opacity: 1 },
  exit: { y: 1000, opacity: 1 },
  transition: {
    duration: 0.5
  }
};
