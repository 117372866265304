import { React, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import InputGeneral from '../Input/InputGeneral';
import log from '../../utils/Logger';

export default function Search({ searchText, setSearchText, borderClass }) {
  const onReset = () => {
    setSearchText('');
  };

  return (
    <div className="relative flex flex-row justify-start items-start">
      <InputGeneral
        value={searchText}
        placeholder="Suche..."
        onChange={(newValue) => {
          setSearchText(newValue);
        }}
        onKeyUp={(event) => {
          event.preventDefault();
          event.target.blur();
        }}
        className={
          ' placeholder-lightGray bg-white px-8 py-2 text-black w-full my-1 ' + borderClass
        }></InputGeneral>
      <div className=" m-auto py-3 my-1">
        {searchText && <IoCloseSharp className=" text-xl" onClick={onReset} />}
      </div>
    </div>
  );
}
