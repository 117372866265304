function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const getRandomPadding = () => {
  const randomNum = getRandomInt(8);
  const randomPaddingY =
    randomNum > 5
      ? ' pt-8 '
      : randomNum > 4
      ? ' pt-5 '
      : randomNum > 3
      ? ' pt-3 '
      : randomNum > 2
      ? ' pb-2 '
      : randomNum > 1
      ? ' pb-5 '
      : 'pb-8';
  const randomNum2 = getRandomInt(8);
  const randomPaddingX =
    randomNum2 > 5
      ? ' pl-8 '
      : randomNum2 > 4
      ? ' pl-5 '
      : randomNum2 > 3
      ? ' pl-3 '
      : randomNum2 > 2
      ? ' pr-2 '
      : randomNum2 > 1
      ? ' pr-5 '
      : 'pr-8';
  return randomPaddingY + randomPaddingX;
};
