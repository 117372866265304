import Konva from 'konva';
import { useRef, useState } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import { CANVAS_CM_TO_PIXEL_RATIO, CANVAS_HEIGHT_CM, CANVAS_WIDTH_CM } from '../../AppConfig';
import log from '../../utils/Logger';

// needed for pinch scale
Konva.hitOnDragEnabled = true;

export const URLImage = ({
  image,
  collectibleId,
  onChange,
  onClickAnimation,
  onImageClick,
  stopOnboardingAnimation,
  setShowBin,
  readonly = false
}) => {
  const [img] = useImage(image.src, 'Anonymous');
  const [lastDist, setLastDist] = useState(0);
  const [draggingActive, setDraggingActive] = useState(false);
  const [buttonDown, setButtonDown] = useState(false);
  if (img && image) {
    img.width = image.collectibleWidth * CANVAS_CM_TO_PIXEL_RATIO;
    img.height = image.collectibleHeight * CANVAS_CM_TO_PIXEL_RATIO;
  }

  const isOutsideOfCanvas = (e) => {
    return (
      e.target.x() < 0 ||
      e.target.x() > CANVAS_WIDTH_CM * CANVAS_CM_TO_PIXEL_RATIO ||
      e.target.y() < 0 ||
      e.target.y() > CANVAS_HEIGHT_CM * CANVAS_CM_TO_PIXEL_RATIO
    );
  };

  const checkIfValidPosition = (e) => {
    // mark for delete image if moved out of canvas
    if (isOutsideOfCanvas(e)) {
      e.target.opacity(0.2);
    } else {
      e.target.opacity(1);
    }

    const [validX, validY] = getValidPositions(
      e.target.x(),
      e.target.y(),
      image.collectibleWidth,
      image.collectibleHeight,
      e.target.scaleX()
    );
    e.target.x(validX);
    e.target.y(validY);
  };

  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  const checkForScaleStart = (e) => {
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    if (touch1 && touch2) {
      log.debug('start double touch');
      setDraggingActive(true);
    } else {
      setDraggingActive(false);
    }
  };

  const isScalingValid = (e) => {
    // image cant be bigger than canvas and not smaller than 15cm
    const imageWidth = image.collectibleWidth * e.target.scaleX();
    const imageHeight = image.collectibleHeight * e.target.scaleY();
    return (
      imageWidth <= CANVAS_WIDTH_CM &&
      imageHeight <= CANVAS_HEIGHT_CM &&
      (imageWidth >= 30 || imageHeight >= 30)
    );
  };

  const checkForScaleEnd = (e) => {
    log.debug('end touch scale');

    if (draggingActive && isScalingValid(e)) {
      checkIfValidPosition(e);
      const scale = e.target.scaleX();
      log.debug('ok save scaling here, newScale: ', scale);
      onChange(collectibleId, e.target.x(), e.target.y(), scale);
    } else if (draggingActive && e.target.scaleX() > 1) {
      // needed as otherwise pics get stuck if too big
      const scale = e.target.scaleX() * 0.95;
      onChange(collectibleId, e.target.x(), e.target.y(), scale);
    } else if (draggingActive && e.target.scaleX() < 1) {
      // needed as otherwise pics get stuck if too small
      const scale = e.target.scaleX() * 1.05;
      onChange(collectibleId, e.target.x(), e.target.y(), scale);
    }
    setLastDist(0);
    setDraggingActive(false);
  };

  const checkForScaling = (e) => {
    e.evt.preventDefault();
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    if (touch1 && touch2) {
      var dist = getDistance(
        {
          x: touch1.clientX,
          y: touch1.clientY
        },
        {
          x: touch2.clientX,
          y: touch2.clientY
        }
      );

      if (!lastDist) {
        setLastDist(dist);
      }

      var scale = (e.target.scaleX() * dist) / lastDist;

      if (draggingActive && scale > 0 && scale < 10 && isScalingValid(e)) {
        log.debug('ok change scale');
        e.target.scaleX(scale);
        e.target.scaleY(scale);
        setLastDist(dist);
      }
    }
  };

  const onDragEnd = (e) => {
    const remove = e.target.opacity() < 1;
    onChange(collectibleId, e.target.x(), e.target.y(), e.target.scaleX(), remove);
    if (remove) {
      e.target.destroy();
    }
    setShowBin(false);
  };

  const setImagePressed = () => {
    setButtonDown(true);
    setTimeout(() => setButtonDown(false), 300);
  };

  return (
    <Image
      image={img}
      x={image.x}
      y={image.y}
      scaleX={image.scale || 1}
      scaleY={image.scale || 1}
      id={`Placed_${collectibleId}`}
      // I will use offset to set origin to the center of the image
      offsetX={img ? img.width / 2 : 0}
      offsetY={img ? img.height / 2 : 0}
      draggable={!readonly}
      onDragEnd={onDragEnd}
      onDragStart={() => {
        setShowBin(true);
      }}
      onDragMove={(e) => checkIfValidPosition(e)}
      onTouchStart={(e) => {
        stopOnboardingAnimation();
        //onClickAnimation(e.target, e.target.scaleX());
        setImagePressed();
        checkForScaleStart(e);
      }}
      onTouchMove={(e) => checkForScaling(e)}
      onTouchEnd={(e) => {
        checkForScaleEnd(e);
        if (buttonDown) {
          onImageClick(collectibleId);
        } else {
          onImageClick(null);
        }
      }}
    />
  );
};
function getValidPositions(posX, posY, width, height, scale) {
  let validX = posX;
  let validY = posY;
  const minX = 0 + ((width * scale) / 2) * CANVAS_CM_TO_PIXEL_RATIO;
  const minY = 0 + ((height * scale) / 2) * CANVAS_CM_TO_PIXEL_RATIO;
  const maxX =
    CANVAS_WIDTH_CM * CANVAS_CM_TO_PIXEL_RATIO - ((width * scale) / 2) * CANVAS_CM_TO_PIXEL_RATIO;
  const maxY =
    CANVAS_HEIGHT_CM * CANVAS_CM_TO_PIXEL_RATIO - ((height * scale) / 2) * CANVAS_CM_TO_PIXEL_RATIO;
  if (posX < minX) validX = minX;
  if (posX > maxX) validX = maxX;
  if (posY < minY) validY = minY;
  if (posY > maxY) validY = maxY;

  validX = Math.round(validX);
  validY = Math.round(validY);

  return [validX, validY];
}
