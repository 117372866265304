import { areEqual, FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import log from '../utils/Logger';
import { CANVAS_CM_TO_PIXEL_RATIO, REACT_APP_APISERVER } from '../AppConfig';
import ContentSelection from './ContentSelection';
import { useQuery } from '@tanstack/react-query';
import {
  filterHiddenExhibitions,
  getExhibition,
  getExhibitions,
  setActiveExhibition
} from '../services/ExhibitionService';
import { useAppState } from '../store/appState';
import { stopDefaultExhibitionLoop } from '../utils/DefaultExhibitionLoop';
import ListCard from '../components/ListCard';
import { useState, memo } from 'react';
import { filterByText } from '../utils/Filter';
import Modal from '../components/Modal/Modal';
import { ModalErrorAlertInfoMessages } from '../components/Modal/ModalContent';
import { AnimatePresence, motion } from 'framer-motion';
import { listcardAnimationProps, bottomUpAnimationProps } from '../utils/Animation';

const ExhibitionCell = memo(({ columnIndex, rowIndex, style, data }) => {
  const { loadExhibition, items } = data;
  const COLUMNS = 5;
  const CELL_GAP = 16;
  const CELL_GAP_TOP = 6;
  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : Number(style.left) + columnIndex * CELL_GAP,
    right:
      columnIndex === COLUMNS ? style.right : Number(style.right) + columnIndex * CELL_GAP || 0,
    top: rowIndex === 0 ? style.top : Number(style.top) + rowIndex * CELL_GAP_TOP
  };
  const index = rowIndex * COLUMNS + columnIndex;
  const item = items[index];
  const content = (
    <>
      <div className="font-bold">{item?.title}</div>
      <div>{item?.author}</div>
    </>
  );

  return item ? (
    <motion.div key={`${item.id}-card`} {...listcardAnimationProps} style={styles}>
      <ListCard
        data={item}
        key={item.id}
        image={item.media?.url}
        content={content}
        onClick={loadExhibition}
        dropShadow={true}
        zoom={false}
      />
    </motion.div>
  ) : (
    ''
  );
}, areEqual);
ExhibitionCell.displayName = 'ExhibitionCell';

const getItemData = memoizeOne((loadExhibition, items) => ({
  loadExhibition,
  items
}));

export default function ExhibitionSelection({ setModalOpen }) {
  const [searchText, setSearchText] = useState('');
  const [additionalModalOpen, setAdditionalModalOpen] = useState(undefined);
  const query = useQuery({
    queryKey: ['exhibitions'],
    queryFn: getExhibitions,
    cacheTime: 24 * 60 * 60 * 1000
  });
  const setPlacedImages = useAppState((state) => state.setPlacedImages);
  const setShowSaveButton = useAppState((state) => state.setShowSaveButton);
  const adminMode = useAppState((state) => state.adminMode);
  const showSaveButton = useAppState((state) => state.showSaveButton);

  const loadComposition = (exhibition) => {
    log.debug('load comp: ', exhibition);
    setPlacedImages([]);
    setShowSaveButton(false);
    const loadedImages = [];
    exhibition?.exhibitionPictures?.map((pic) => {
      const x = pic.position[0] * CANVAS_CM_TO_PIXEL_RATIO;
      const y = pic.position[1] * CANVAS_CM_TO_PIXEL_RATIO;
      const scale = pic.rotation[0] || 1;
      loadedImages.push({
        x,
        y,
        scale,
        src: REACT_APP_APISERVER + pic.collectible?.previewMedia?.url,
        collectibleId: pic.collectible.id,
        collectibleWidth: pic.collectible.width,
        collectibleHeight: pic.collectible.height
      });
    });
    setPlacedImages(loadedImages);
    setActiveExhibition(exhibition?.id);
    stopDefaultExhibitionLoop();
    setModalOpen(undefined);
  };

  const loadExhibition = async (exhibition) => {
    log.debug('load exhibition called for, ', exhibition.id);
    if (showSaveButton) {
      // show warning to user unsaved exhibition will be overwritten
      setAdditionalModalOpen(exhibition.id);
    } else {
      const ex = await getExhibition(exhibition.id);
      loadComposition(ex);
    }
  };

  const filterExhibitions = (exhibitions) => {
    if (!exhibitions) return [];
    let filteredExhibitions = filterHiddenExhibitions(exhibitions, adminMode);
    if (!searchText) return filteredExhibitions;
    filteredExhibitions = filterByText(searchText, filteredExhibitions);
    log.debug('filteredExhibitions: ', filteredExhibitions);
    return filteredExhibitions;
  };

  const COLUMNS = 5;

  const itemData = query.data ? getItemData(loadExhibition, filterExhibitions(query.data)) : [];

  const getItemKey = (rowIndex, columnIndex, data) => {
    const index = rowIndex * COLUMNS + columnIndex;
    console.log('data: ', data);
    return data.items[index]?.id + '-griditem-' + columnIndex;
  };

  return (
    <motion.div key={'exhibitionModal'} {...listcardAnimationProps}>
      <Modal
        onClose={() => {
          setModalOpen(undefined);
        }}
        size=" w-11/12 "
        showBackHint={true}>
        <ContentSelection
          title="HALL OF FAME"
          searchText={searchText}
          filterBySearch={setSearchText}
          showTags={false}
          id="exhibitionselection">
          <div className="h-3/4 w-full pl-6 flex justify-items-start content-start gap-4 flex-row px-4 flex-wrap overflow-y-auto scrollbar-hide">
            <AnimatePresence initial={false}>
              <AutoSizer>
                {({ height, width }) => (
                  <Grid
                    columnCount={COLUMNS}
                    columnWidth={198}
                    height={height}
                    rowCount={Math.ceil(filterExhibitions(query.data).length / COLUMNS)}
                    rowHeight={298}
                    width={width}
                    itemKey={(data) => getItemKey(data.rowIndex, data.columnIndex, data.data)}
                    itemData={itemData}>
                    {ExhibitionCell}
                  </Grid>
                )}
              </AutoSizer>
            </AnimatePresence>
          </div>
        </ContentSelection>
        {additionalModalOpen && (
          <Modal
            onClose={() => {
              setModalOpen(undefined);
            }}>
            <ModalErrorAlertInfoMessages
              message="Deine Änderungen gehen beim Öffnen dieser Anordnung verloren."
              confirm={async () => {
                const ex = await getExhibition(additionalModalOpen);
                loadComposition(ex);
              }}
              confirmText="OK"></ModalErrorAlertInfoMessages>
          </Modal>
        )}
      </Modal>
    </motion.div>
  );
}
