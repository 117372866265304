import { EXHIBITIONS_INTERVAL_TIME } from '../AppConfig';
import { setActiveExhibition } from '../services/ExhibitionService';
import log from '../utils/Logger';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
let activeIndex = 0;
let defaultExhibitions = [];
let interval;

export const startDefaultExhibitionLoop = async (exhibitions) => {
  if (interval) return;
  log.debug('ok start loop called 1s, ', exhibitions);
  defaultExhibitions = exhibitions.filter((ex) => ex.isDefault);
  log.debug('ok start loop called 2, ', defaultExhibitions);
  test();
  interval = setInterval(test, EXHIBITIONS_INTERVAL_TIME);
};

export const stopDefaultExhibitionLoop = () => {
  log.debug('ok loop interval cleared');
  clearTimeout(interval);
  interval = undefined;
};

function test() {
  log.debug('loop is active!', activeIndex);
  if (defaultExhibitions[activeIndex]?.id) {
    setActiveExhibition(defaultExhibitions[activeIndex].id);
  }

  activeIndex = activeIndex > defaultExhibitions.length - 2 ? 0 : activeIndex + 1;
}
