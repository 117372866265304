import { useState } from 'react';
import { motion } from 'framer-motion';
import { useAppState } from '../../store/appState';
import LoginForm from '../Admin/LoginForm';
import AdminModeButton from '../Button/AdminModeButton';
import log from '../../utils/Logger';

export function Page({ bg = '', children, classAddon = '', animationType }) {
  const [timesClicked, setTimesClicked] = useState(0);
  const setAdminMode = useAppState((state) => state.setAdminMode);
  const adminMode = useAppState((state) => state.adminMode);
  const [modalOpen, setModalOpen] = useState(undefined);

  const onAdminFieldClicked = () => {
    log.debug('ok admin field clicked: ' + timesClicked);
    if (timesClicked >= 5 && adminMode == 0) {
      setAdminMode(1);
      setTimesClicked(0);
      return;
    }
    if (adminMode == 1) {
      setModalOpen('loginModal');
      return;
    }

    if (adminMode == 2) {
      //TODO: logout and login with default user
      setAdminMode(0);
      return;
    }

    // reset counter if not pressed fast enough
    if (timesClicked == 0) {
      setTimeout(() => setTimesClicked(0), 3000);
    }
    setTimesClicked(timesClicked + 1);
  };

  const initial =
    animationType == 'vertical-up'
      ? { y: 0, height: 0, opacity: 0.5 }
      : animationType == 'vertical-down'
      ? { y: -window.innerHeight, height: '100vh', opacity: 0.5 }
      : { x: '100vw', y: 0, opacity: 0 };
  const animate = {
    width: '100vw',
    height: '100vh',
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 1, ease: 'easeInOut' }
  };
  const exit =
    animationType == 'vertical-up'
      ? { y: window.innerHeight, opacity: 0.5, transition: { duration: 0.3 } }
      : animationType == 'vertical-down'
      ? { height: 0, opacity: 0.5, y: window.innerHeight, transition: { duration: 0.3 } }
      : { x: '-100vw', opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } };

  const variants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: { delay: -1, duration: 1.5 }
    },
    exit: {
      x: '-100vw',
      transition: { ease: 'easeInOut' }
    }
  };

  return (
    <div className="relative h-screen w-screen">
      <div className={bg + ' bg-cover bg-center h-full ' + classAddon}>{children}</div>
      <AdminModeButton onButtonClicked={onAdminFieldClicked} adminMode={adminMode} />
      {modalOpen && <LoginForm setModalOpen={setModalOpen} />}
    </div>
  );
}
